import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getShiftsAndStaffingPath } from "./shiftsAndStaffing";

const ShiftPlan = lazyWithRetry(
  () => import("../../ShiftsAndStaffing/pages/ShiftPlan/ShiftPlan")
);

export const shiftPlanRoute: RouteObject = {
  path: ":shiftPlanId",
  children: [{ index: true, element: <ShiftPlan /> }],
};

export function getShiftPlanPath(
  solutionId: string | number,
  shiftPlanId: string | number
): string {
  return `${getShiftsAndStaffingPath(solutionId)}/${shiftPlanId}`;
}
